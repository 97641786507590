var $ = require("jquery");

(function () {
    var advertisementTakeover = "[data-advertisement-takeover]";
    var advertisementTakeoverToggler = "[data-advertisement-takeover-toggler]";

    if ($(advertisementTakeover).length > 0) { 
        if (!sessionStorage.getItem('advertisement-takeover-closed')) {
            $('body').addClass('freeze');
            $(advertisementTakeover).css('display', 'flex');
        }
        else {
            $(advertisementTakeover).remove();
            $('body').removeClass('freeze');
        }
    }

    $(document).on('click', advertisementTakeoverToggler, function (event) {
        event.preventDefault();

        sessionStorage.setItem('advertisement-takeover-closed', true);
        $(advertisementTakeover).remove();
        $('body').removeClass('freeze');
    });
})();

