var $ = require("jquery");
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";

(function () {
    var carousels = $('[data-image-gallery]');

    if (carousels.length > 0) {
        $(carousels).each(function(x, item) { 
            var mainCarousel = new Carousel($(item).find('[id^="mainCarousel_"]')[0], {
                Dots: false,
            });

            // Thumbnails
            var thumbCarousel = new Carousel($(item).find('[id^="thumbCarousel_"]')[0], {
                Sync: {
                    target: mainCarousel,
                    friction: 0,
                },
                Dots: false,
                Navigation: false,
                center: false,
                slidesPerPage: 1,
                infinite: false,
            });

            // Customize Fancybox
            Fancybox.bind('[data-fancybox^="gallery_"]', {
                Carousel: {
                    on: {
                        change: (that) => {
                            mainCarousel.slideTo(mainCarousel.findPageForSlide(that.page), {
                                friction: 0,
                            });
                        },
                    },
                }
            });
        })
    }
})();

