import Glide from '@glidejs/glide'

const sliders = Array.from(document.querySelectorAll(".glide"));

sliders.forEach(slider => {
    new Glide(slider, {
        autoplay: 5000,
        animationDuration: 2000,
        gap: 0,
        hoverpause: true,
        perView: 1,
        type:"carousel"
    }).mount()
})

