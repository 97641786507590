var $ = require("jquery");

(function () {
	//TOP NAVIGATION
	var headerTopWrapper = "[data-navigation-wrapper]";
	var $headerTopWrapper = $(headerTopWrapper);
	var topNavigation = "[data-navigation]";
	var $topNavigation = $(topNavigation)
	var topNavigationToggler = "[data-navigation-toggler]";
	var topNavigationDropdown = "[data-navigation-dropdown]";
	var topNavigationDropdownOverlay = "[data-navigation-dropdown-overlay]";
	var $topNavigationDropdown = $(topNavigationDropdown);
	var topNavigationDropdownPart = "[data-navigation-dropdown-part]";
	var $topNavigationDropdownPart = $(topNavigationDropdownPart);
	var subMenuLevels = "[data-sub-menu-level]";
	var $subMenuLevels = $(subMenuLevels);
	var $quickSearch = $("[data-quick-search]");
	var quickSearchToggler = "[data-quick-search-toggler]";
	var quickSearchClose = "[data-quick-search-close]";

	$topNavigation.on("mouseover", topNavigationToggler, function (event) {
		event.preventDefault();
		$quickSearch.removeClass("active");

		var $this = $(this);
		var $parent = $this.parents().filter(topNavigation);

		if (!$this.hasClass('active')) {
			if ($this.hasClass('has-children')) {
				$('[data-navigation-item]').removeClass('open'); 
				$('.Header').addClass('open');
				$this.addClass('open');
				$(topNavigationDropdownOverlay).addClass('open');
				$parent.addClass("active");
				$headerTopWrapper.addClass("menu-active");

				showActiveMegaMenu($this.text());
			}
			else {
				closeMegamenu()
			}
		}
	});

	$(document).on('click', quickSearchToggler, function (event) {
		event.preventDefault();

		$quickSearch.toggleClass('-active');

		if ($quickSearch.hasClass('-active')) {
			$quickSearch.find('input[name=query]').focus()
		}
	})

	$(document).on('click', quickSearchClose, function (event) {
		if ($(event.target).is('a'))
			event.preventDefault();

		if ($(event.target).parents('.Search-modal').length == 0) {
			$quickSearch.removeClass('-active');
		}
	})

	function showActiveMegaMenu(pageName) {
		$topNavigationDropdown.removeClass('closed').addClass('open');

		$topNavigationDropdownPart.each(function (index, value) {
			var $item = $(value);
			$item.removeClass('active');

			if ($headerTopWrapper.hasClass("menu-active") && $item.data('page-name').trim() == pageName.trim()) {
				$item.addClass('active');

				SetMenuItemHeight($(topNavigationDropdownPart + '.active'))
				SetDropdownHeight();
			}
		})
	}

	function closeMegamenu() {
		$topNavigation.removeClass("active");
		$subMenuLevels.removeClass("active");
		$('.Header').removeClass('open');
		$('.Header').find('.open').removeClass('open')
		$(topNavigationDropdownOverlay).removeClass('open');
		$headerTopWrapper.removeClass("menu-active");
		$topNavigationDropdown.addClass('closed').removeClass('open');
		$topNavigationDropdown.css('height', '')
		$topNavigationDropdownPart.removeClass('active');
		$subMenuLevels.find(".expanded").addClass("active");
	}

	$(document).on("mouseover", function (event) {
		if ($(event.target).closest('.Menu-dropdown').length > 0)
			return
		if ($(event.target).closest('.Header').length > 0)
			return

		closeMegamenu()
	});

	$(window).on('load', function () {
		$topNavigationDropdownPart.each(function () {
			SetMenuItemHeight($(this));
		});
	});

	$(window).on('resize', function () {
		$topNavigationDropdownPart.each(function () {
			SetMenuItemHeight($(this));
		});
	});

	function SetDropdownHeight() {
		var $activeSubmenu = $topNavigationDropdown.find(topNavigationDropdownPart + ".active");

		var height = ''

		if ($activeSubmenu.length > 0)
			height = $activeSubmenu.outerHeight(true) + 'px'

		$topNavigationDropdown.css('height', height);
	}

	function SetMenuItemHeight($element) {
		var $activeSubmenus = $element.find(subMenuLevels + ".active");
		var maxHeight = Math.max.apply(null, $activeSubmenus.map(function () {
			return $(this).outerHeight(true);
		}).get());

		maxHeight = maxHeight + 50;

		if (maxHeight > 0 && maxHeight < window.innerHeight)
			$element.css("min-height", maxHeight + "px");
		else if (maxHeight > 0)
			$element.css("min-height", window.innerHeight - 100 + "px");
	}

	//PALM NAVIGATION!
	var $palmToggler = $("[data-navigation-palm-toggler]");
	var $palmMenuWrapper = $("[data-navigation-palm-wrapper]");
	var $palmItemToggler = $("[data-navigation-item-toggler]");

	function openPalmMenu() {
		$palmToggler.addClass('active');
		$palmMenuWrapper.removeClass('closed').addClass("open");
		$(".Header").addClass('open');
	}

	function closePalmMenu() {
		$palmToggler.removeClass('active');
		$palmMenuWrapper.addClass('closed').removeClass("open");
		$(".Header").removeClass('open');
	}

	$palmToggler.on("click", function (event) {
		event.preventDefault();

		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			closePalmMenu();
		}
		else {
			$(this).addClass('active');
			openPalmMenu();
		}
	});

	$palmItemToggler.on('click', function () {
		var $item = $(this).closest('[data-navigation-item]')
		$item.toggleClass('open')
		$item.next().slideToggle('fast', function () {
			$(this).toggleClass('open')
		});
	});
})();