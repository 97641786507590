var $ = require("jquery");

(function () {
	var expandable = '[data-expandable-text]';
	var expandableToggle = '[data-expandable-text-toggler]';

	$(document).on('click', expandableToggle, function (e) {
		e.preventDefault();

		$(expandable).toggleClass('active');
		if ($(expandable).hasClass('active')) {
			$(expandableToggle).html('Visa mindre')
		}
		else {
			$(expandableToggle).html('Visa mer')

			$('html, body').animate({
				scrollTop: $(expandable).offset().top
			}, 0);
		}
		
	});
})();