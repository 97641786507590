let scriptLoaded = null;
window.captcha = {

    WaitScriptLoaded: function (resolve) {
        if (typeof (grecaptcha) !== 'undefined' && typeof (grecaptcha.render) !== 'undefined')
            resolve();
        else
            setTimeout(() => captcha.WaitScriptLoaded(resolve), 100);
    },

    Init: function (siteKey) {
        const scripts = Array.from(document.getElementsByTagName('script'));
        if (!scripts.some(s => (s.src || '').startsWith('https://www.google.com/recaptcha/api.js'))) {
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?render=' + siteKey;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
        if (scriptLoaded === null)
            scriptLoaded = new Promise(captcha.WaitScriptLoaded);
        return scriptLoaded;
    },

    Fetch: function (siteKey) {

        return new Promise((resolve, reject) => {
            grecaptcha.ready(function () {
                grecaptcha.execute(siteKey, { action: 'submit' })
                    .then(function (token) {
                        resolve(token);
                    });
            });
        });
    },

    GetResponse: function (widgetId) {
        return grecaptcha.getResponse(widgetId);
    }
}