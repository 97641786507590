var $ = require("jquery");

var scroll;
var hideAt = 136;

(function () {

    if ($(window).scrollTop() > 10) {
        onScroll();
    }

    $(document).on('scroll', onScroll)

    function onScroll() {
        var currentScroll = $(window).scrollTop();

        if (scroll && currentScroll < scroll) {
            $(".Header").css('top', 0);
        }
        else if (scroll && currentScroll > hideAt) {
            $(".Header").css('top', -$('.Header').outerHeight());
        }

        scroll = currentScroll;
    }
})();

