var $ = require("jquery");

(function () {
	var expandable = '[data-expandable]';
	var expandableToggle = '[data-expandable-toggle]';
	var expandableContent = '[data-expandable-content]';

	const hash = window.location.hash;

	$(document).on('click', expandableToggle, function (e) {
		e.preventDefault();

		var $self = $(this);
		var $expandable = $self.closest(expandable)
		$expandable.toggleClass('open');
		$expandable.find(expandableContent).slideToggle('fast');

		var ariaExpandable = $self.attr('aria-expanded');
		$self.attr('aria-expanded', ariaExpandable == 'true' ? 'false' : 'true');
		$self.toggleClass('collapsed');
	});

	if (hash) {
		const $elementToExpand = $(hash)
		const $expandable = $elementToExpand.closest(expandable)
		$expandable.toggleClass('open');
		$expandable.find(expandableContent).slideToggle('fast');

		const ariaExpandable = $elementToExpand.attr('aria-expanded');
		$elementToExpand.attr('aria-expanded', ariaExpandable == 'true' ? 'false' : 'true');
		$elementToExpand.toggleClass('collapsed');

		const arrowIcon = $expandable.find(".Icon");
		arrowIcon.toggleClass("-open");
	}
})();