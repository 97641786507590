var $ = require("jquery");

var scroll;
var hideAt = 136;

(function () {
    $(document).on('focus', '.input-validation-error', function () {
        $(this).removeClass('input-validation-error');
        if ($(this).parents('.validation-container').length > 0) {
            $(this).parents('.validation-container').siblings('.Form-validation').remove();
        }
        else {
            $(this).siblings('.Form-validation').remove();
        }

        $(this).removeClass('input-validation-error');
    })
})();

